import { first, matches } from 'lodash'

export default {
	init () {
		const cards = Array.from(document.querySelectorAll('.memory-card'))
		const memoryGameContainer = document.querySelector('.memory-game')

		function shuffleCards (array) {
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1))
				;[array[i], array[j]] = [array[j], array[i]]
			}
			return array
		}
		const shuffledCards = shuffleCards(cards)
		memoryGameContainer.innerHTML = ''
		shuffledCards.forEach(card => {
			memoryGameContainer.appendChild(card)
		})

		let hasFlippedCard = false
		let lockBoard = false
		let firstCard, secondCard
		let matchesFound = 0

		function flipCard () {
			if (lockBoard) return
			if (this === firstCard) return

			this.classList.add('flip')

			if (!hasFlippedCard) {
				hasFlippedCard = true
				firstCard = this

				return
			}

			secondCard = this
			checkForMatch()
		}

		function checkForMatch () {
			let isMatch = firstCard.dataset.icon === secondCard.dataset.icon

			isMatch ? disableCards() : unflipCards()
		}

		function disableCards () {
			firstCard.removeEventListener('click', flipCard)
			secondCard.removeEventListener('click', flipCard)

			firstCard.classList.add('disappear')
			firstCard.setAttribute('tabindex', '-1')
			secondCard.classList.add('disappear')
			secondCard.setAttribute('tabindex', '-1')

			matchesFound++

			if (matchesFound === 6) {
				endScreen()
			} else {
				resetBoard()
			}
		}

		function unflipCards () {
			lockBoard = true

			setTimeout(() => {
				firstCard.classList.remove('flip')
				secondCard.classList.remove('flip')

				resetBoard()
			}, 1500)
		}

		function resetBoard () {
			;[hasFlippedCard, lockBoard] = [false, false]
			firstCard = null
			secondCard = null
		}

		cards.forEach(card => card.addEventListener('click', flipCard))

		const revealElement = document.querySelector('.reveal-element')
		const details = document.querySelectorAll('.hide')
		const decor = document.querySelector('.deco')
		function endScreen () {
			revealElement.classList.add('reveal')
			decor.classList.add('shorten')
			details.forEach(detail => {
				detail.classList.add('reveal')
			})
		}
	},
	finalize () {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
}
